import ReactDOM from "react-dom/client";
import React from "react";

import App from "./App";

import "bootstrap/scss/bootstrap.scss";
import "react-toastify/scss/main.scss";
import "./assets/scss/fontawesome.scss";
import "./assets/scss/brands.scss";
import "./assets/scss/regular.scss";
import "./assets/scss/solid.scss";
import "./assets/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
