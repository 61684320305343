export default function EmailLink({ children, email }) {
  function handleEmailClick(e) {
    e.preventDefault();
    const url = `mailto:${email}`;
    window.location.href = url;
  }
  return (
    <span className="email-link" onClick={handleEmailClick}>
      {children}
    </span>
  );
}
