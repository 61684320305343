import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
//import useSWR from "swr";

import PortfolioList from "./components/controls/portfolio-list/portfolio-list.component";
//import ContentLoader from "./components/common/content-loader/content-loader.component";
import ServicesList from "./components/controls/services-list/services-list.component";
//import ContentError from "./components/common/content-error/content-error.component";
import SectionTitle from "./components/common/section-title/section-title.component";
import GradientText from "./components/common/gradient-text/gradient-text.component";
import EmailLink from "./components/common/email-link/email-link.component";
import ClientsList from "./components/controls/clients-list/clients-list.component";
import Button from "./components/controls/button/button.component";
// import { getSocial } from "./models/social.model";
//import { SETTINGS } from "./utils/config.utils";

import { ReactComponent as Logo } from "./assets/logo.svg";

import data from "./assets/data.json";

export default function App() {
  const [showMenu, setShowMenu] = useState(false);

  function handleToggleMenu() {
    setShowMenu((show) => !show);
  }

  return (
    <div className="app">
      <ToastContainer position="top-center" autoClose={5000} theme="colored" />
      <Menu show={showMenu} onClose={handleToggleMenu} />
      <Header onToggleMenu={handleToggleMenu}>
        A dynamic creative department that is
        <br />
        &nbsp;a seamless extenstion to your team
      </Header>
      <WhoWeAre />
      <WhatWeOffer />
      <Master />
      <Clients />
      <Footer />
    </div>
  );
}

function Header({ onToggleMenu, children }) {
  return (
    <header id="home">
      <Container>
        <Row>
          <Col>
            <Button onClick={onToggleMenu}>Menu</Button>
          </Col>
        </Row>
        <Row>
          <Col className="header-content">
            <div className="logo-container">
              <Logo />
            </div>
            <p className="header-text">{children}</p>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

function Menu({ show, onClose }) {
  const { menu } = data;

  function handleClick(target) {
    onClose();
    window.scroll({
      top: document.querySelector(target).offsetTop - 70,
      behavior: "smooth",
    });
  }

  return (
    show && (
      <div className="off-canvas-menu">
        <button className="off-canvas-close" onClick={onClose}>
          <span className="fa fa-times"></span>
        </button>
        <div className="menu-logo">
          <Logo />
        </div>
        <ul>
          {menu.map(({ id, title, target }) => (
            <li key={id}>
              <button onClick={() => handleClick(target)}>{title}</button>
            </li>
          ))}
        </ul>
      </div>
    )
  );
}

function WhoWeAre() {
  return (
    <div id="about">
      <SectionTitle>Who We Are</SectionTitle>
      <div className="content">
        <Container>
          <Row>
            <Col className="text-center">
              <p className="light-text narrow-text">
                A community of creatives from all over the world working
                together to solve your creative problems under a new business
                model
              </p>
              <GradientText className="large-text narrow-text">
                We're a Seamless Extenstion of Your Team
              </GradientText>
            </Col>
          </Row>
          <Row className="justify-content-center mb-5">
            <Col lg={5}>
              <p>
                People that join the team are looking beyond ticking the boxes
                and looking for the next paycheck. These people are creatives
                passionate about what they do and want to talk about it.
                Discussions, healthy competition, and growth are the core of the
                community dynamics.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function WhatWeOffer() {
  const { services } = data;

  return (
    <div className="black-section" id="services">
      <SectionTitle blackMode={true}>What We Offer</SectionTitle>
      <div className="section-content">
        <Container>
          <Row>
            <Col className="text">
              <h3>Your Extended Team!</h3>
              <p>
                We're more than "an agency",&nbsp;
                <strong>we're seamless extenstion of your team</strong>, we
                amplify your
                <br />
                &nbsp; brand's essence , operating remotely with precision and
                speed, and delivering outstanding
                <br />
                &nbsp; value without the extra overheads. We can fill the gaps
                in:
              </p>
            </Col>
          </Row>
        </Container>
        <ServicesList list={services} />
        <Container>
          <Row>
            <Col className="outro">
              <p>
                We run with your passion, vision, and intelligence. Let's
                <br />
                &nbsp; make the remarkable happen together.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function Master() {
  const { portfolio } = data;
  return (
    <div id="portfolio">
      <SectionTitle>We Have Humbly Mastered</SectionTitle>
      <div className="section-content">
        <PortfolioList list={portfolio} />
      </div>
    </div>
  );
}

function Clients() {
  const { clients } = data;
  return (
    <div className="black-section" id="clients">
      <SectionTitle blackMode={true}>Clients</SectionTitle>
      <div className="section-content">
        <ClientsList list={clients} />
      </div>
    </div>
  );
}

function Footer() {
  function goToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  return (
    <footer id="contact">
      <FooterContent handleGoToTop={goToTop} />
      <FooterContact handleGoToTop={goToTop} />
      <FooterSocial />
    </footer>
  );
}

function FooterContent({ handleGoToTop }) {
  return (
    <Container className="content">
      <Row className="align-items-center">
        <Col lg={8}>
          <p>
            We are based in the <strong>United Arab Emirates</strong>
            <br />
            &nbsp; but we have talent from all over the world.
          </p>
        </Col>
        <Col lg={4} className="text-right">
          <div className="back-to-top" onClick={handleGoToTop}>
            <span className="fa fa-arrow-up"></span>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function FooterContact({ handleGoToTop }) {
  return (
    <Container className="contact">
      <Row>
        <Col>
          <aside>
            <h3>Let's Talk</h3>
            <p>
              For general inquiries, drop us an email and we will get back to
              you
              <br />
              &nbsp; as soon as possible:
            </p>
            <EmailLink email="info@thecollectivemind.me">
              info<span className="fa fa-at"></span>collectivemind.me
            </EmailLink>
            <p>Or talk to our multi-talented operational manager:</p>
            <EmailLink email="sinan@thecollectivemind.me">
              sinan<span className="fa fa-at"></span>collectivemind.me
            </EmailLink>
          </aside>
          <div className="back-to-top mobile" onClick={handleGoToTop}>
            <span className="fa fa-arrow-up"></span>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function FooterSocial() {
  // const {
  //   data: social,
  //   error,
  //   isLoading,
  // } = useSWR("/api/social-links", getSocial);

  // if (error && SETTINGS.debug) console.log(error);
  // if (social && !isLoading && SETTINGS.debug) console.log(social);

  const { social } = data;

  return (
    <Container className="social">
      <Row>
        <Col lg={6}>
          <span className="copyright">&copy;{new Date().getFullYear()}</span>
        </Col>
        <Col lg={6}>
          {/* {error ? (
            <div className="loading-container">
              <ContentError />
            </div>
          ) : isLoading ? (
            <div className="loading-container">
              <ContentLoader />
            </div>
          ) : (
          )} */}
          <ul>
            {social.map(({ id, url, title }) => (
              <SocialItem key={id} url={url} title={title} />
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

function SocialItem({ url, title }) {
  return (
    <li>
      <a href={url} target="_blank" rel="noreferrer">
        {title}
      </a>
    </li>
  );
}
