import { Container, Row, Col, Modal } from "react-bootstrap";
import { useState } from "react";
//import useSWR from "swr";

// import ContentLoader from "../../common/content-loader/content-loader.component";
// import ContentError from "../../common/content-error/content-error.component";
// import { getServices } from "../../../models/service.model";
// import { SETTINGS } from "../../../utils/config.utils";

import "./services-list.styles.scss";

export default function ServicesList({ list }) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  function handleClose() {
    setShow(false);
  }

  function handleOpen(modalTitle, modalDescription) {
    setTitle(modalTitle);
    setText(modalDescription);
    setShow(true);
  }

  // const {
  //   data: services,
  //   error,
  //   isLoading,
  // } = useSWR("/api/services", getServices);

  // if (error && SETTINGS.debug) console.log(error);
  // if (services && !isLoading && SETTINGS.debug) console.log(services);

  return (
    <>
      <Container>
        <div className="services-list">
          <Row className="justify-content-center">
            {/* {error ? (
            <Col className="loading-container">
              <ContentError />
            </Col>
          ) : isLoading ? (
            <Col className="loading-container">
              <ContentLoader />
            </Col>
          ) : (
            )} */}
            {list.map(({ title, description, details, id }) => (
              <Col key={id} lg={4} xs={6}>
                <ServiceItem
                  title={title}
                  description={description}
                  showMore={details && details.length > 0}
                  onOpen={() => {
                    handleOpen(title, details);
                  }}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <ServiceModal title={title} onClose={handleClose} show={show}>
        {text}
      </ServiceModal>
    </>
  );
}

function ServiceItem({ title, description, onOpen, showMore }) {
  const [active, setActive] = useState(false);
  const handleHover = () => {
    setActive((active) => !active);
  };
  return (
    <div
      className={`service-item${active ? " in" : ""}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <div className="inner">
        <h4>{title}</h4>
      </div>
      <div className="cover">
        <h4>{title}</h4>
        <p>{description}</p>
        {showMore && <button onClick={onOpen}>Read More</button>}
      </div>
    </div>
  );
}

function ServiceModal({ children, title, show, onClose }) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      centered
      fullscreen="md-down"
      scrollable
      className="dark-modal service-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{children}</p>
      </Modal.Body>
    </Modal>
  );
}
