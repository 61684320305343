import { Container, Row, Col } from "react-bootstrap";

import "./section-title.styles.scss";

export default function SectionTitle({ children, blackMode }) {
  return (
    <Container
      fluid={true}
      className={`section-title${blackMode ? " section-title--black" : ""}`}
    >
      <Row>
        <Col>
          <h2>{children}</h2>
        </Col>
      </Row>
    </Container>
  );
}
