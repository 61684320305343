import { Container, Row, Col } from "react-bootstrap";
//import useSWR from "swr";

// import ContentLoader from "../../common/content-loader/content-loader.component";
// import ContentError from "../../common/content-error/content-error.component";
import GradientText from "../../common/gradient-text/gradient-text.component";
// import { getPortfolio } from "../../../models/portfolio.model";
// import { SETTINGS } from "../../../utils/config.utils";

import "./portfolio-list.styles.scss";

export default function PortfolioList({ list }) {
  // const {
  //   data: portfolio,
  //   error,
  //   isLoading,
  // } = useSWR("/api/portfolio", getPortfolio);

  // if (error && SETTINGS.debug) console.log(error);
  // if (portfolio && !isLoading && SETTINGS.debug) console.log(portfolio);

  return (
    <Container>
      <div className="portfolio-list">
        {/* {error ? (
          <Row className="loading-container">
            <Col>
              <ContentError />
            </Col>
          </Row>
        ) : isLoading ? (
          <Row className="loading-container">
            <Col>
              <ContentLoader />
            </Col>
          </Row>
        ) : (
          )} */}
        {list.map(({ title, subtitle, sort_order, id }) => (
          <Row key={id}>
            <Col>
              <PortfolioItem
                title={title}
                subtitle={subtitle}
                sort_order={sort_order}
              />
            </Col>
          </Row>
        ))}
      </div>
    </Container>
  );
}

function PortfolioItem({ title, subtitle, sort_order }) {
  function getNumber(num) {
    return num < 10 ? `0${num}` : num.toString();
  }
  return (
    <>
      <div className="portfolio-item">
        <div className="inner">
          <span className="number">({getNumber(sort_order)})</span>
          <h3>
            <GradientText>{title}</GradientText>
          </h3>
        </div>
        {subtitle && subtitle.length > 0 && <p>({subtitle})</p>}
      </div>
    </>
  );
}
