import { Container, Row, Col } from "react-bootstrap";
//import useSWR from "swr";

// import ContentLoader from "../../common/content-loader/content-loader.component";
// import ContentError from "../../common/content-error/content-error.component";
// import { getClients } from "../../../models/client.model";
//import Image from "../../common/image/image.component";
//import { SETTINGS } from "../../../utils/config.utils";

import "./clients-list.styles.scss";

export default function ClientsList({ list }) {
  // const {
  //   data: clients,
  //   error,
  //   isLoading,
  // } = useSWR("/api/clients", getClients);

  // if (error && SETTINGS.debug) console.log(error);
  // if (clients && !isLoading && SETTINGS.debug) console.log(clients);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={12}>
          <div className="clients-list">
            {/* {error ? (
              <div className="loading-container">
                <ContentError />
              </div>
            ) : isLoading ? (
              <div className="loading-container">
                <ContentLoader />
              </div>
            ) : (
              )} */}
            {list.map(({ title, logo, id }) => (
              <ClientItem key={id} title={title} logo={logo} />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function ClientItem({ title, logo }) {
  return (
    <div className="client-item">
      {/* <Image src={logo?.data?.attributes?.url} alt={title} /> */}
      <img src={logo} alt={title} />
    </div>
  );
}
