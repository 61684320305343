import "./gradient-text.styles.scss";

export default function GradientText({ children, className, ...otherProps }) {
  return (
    <p
      className={`gradient-text${className ? ` ${className}` : ""}`}
      {...otherProps}
    >
      {children}
    </p>
  );
}
